<template>
  <div class="profile" :class="localDisplayLang === 'ara' ? 'rtl-profile' : ''" id="profile">
    <div
      class="profile-detail"
      id="profile-detail"
      :style="[providerName === 'Noor Play' ? { 'border-radius': '10px' } : '']"
    >
      <!-- <div class="profile-detail-logout">
        <img src="@/assets/icons/Logout.svg" alt @click="logout" />
        <p @click="logout">{{ $t('logout') }}</p>
      </div> -->
      <div class="profile-detail-userdetails">
        <img
          class="profile-detail-userdetails-profileIcon"
          style="border-radius:50%;"
          v-if="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
          :src="subscriberProfileDetails ? subscriberProfileDetails.picture : subscriberDetails ? subscriberDetails.picture : null"
          alt
        />
        <img class="profile-detail-userdetails-profileIcon" v-else src="@/assets/icons/ProfileDp.png" alt />
        <p class="profile-detail-userdetails-username">{{ getSubscriberProfileName }}</p>
        <div v-if="subscriberDetails.data.email">
          <p class="profile-detail-userdetails-email" v-if="subscriberDetails.data.email.length < 16">
            {{ subscriberDetails.data.email }}
          </p>

          <p class="profile-detail-userdetails-email" v-else>{{ subscriberDetails.data.email.substring(0, 16) }}...</p>
        </div>

        <div v-if="subscriberDetails.data.mobileno">
          <p class="profile-detail-userdetails-email" v-if="subscriberDetails.data.mobileno">
            {{ subscriberDetails.data.mobileno }}
          </p>
        </div>
        <!-- <router-link to="/profile"  > -->
          <button
          v-if="$route.name !== 'Profile' && !checkIfKidsProfile"
          @click="actRoutingProfile()"
            class="button-primary profile-detail-userdetails-gotoProfile"
            :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          >
            <span class="btn-txt-color"> {{ $t("go to profile") }} </span>
          </button>
        <!-- </router-link> -->
        <div v-if="checkIfMultipleProfileEnabled" class="profile-detail-userdetails-switchProfile" @click="goToSwitchProfile" >
          <!-- <router-link to="/switchProfile" > -->
          <button
            class="profile-detail-userdetails-switchProfile-switchButton"
          >
            <span class="btn-txt-color">{{ $t("switch profile") }} </span>
          </button>
        <!-- </router-link> -->
        </div>
      </div>
      <div class="profile-detail-logout">
        <img src="@/assets/icons/Logout.svg" alt @click="logout" />
        <p @click="logout">{{ $t("logout") }}</p>
      </div>
    </div>
    <div class="profile-switch" v-if="!providerName === 'Noor Play'">
      <p class="profile-switch-title">{{ $t("switch profile") }}</p>
      <div v-if="noProfiles && profiles.length === 0">
        <Loading></Loading>
      </div>
      <div class="profile-switch-noprofiles" v-if="!noProfiles && profiles.length === 0">
        <p>{{ $t("no profiles found") }}</p>
      </div>
      <!-- <div class="profile-switch-subprofiles" :style="[profiles.length > 3 ? { overflowY: 'scroll' } : '']" v-else>
        <div v-for="(profile, index) in profiles" :key="index">
          
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  data() {
    return {
      userName: "Ayan Mandal",
      userEmail: "ayan.mandal@mobiotics.com",
      subscriberDetails: null,
      profiles: [],
      loader: false,
      displayPic: require("@/assets/icons/ProfileDp.png"),
      profileError: null,
      noProfiles: true,
      providerUniqueId: _providerId,
      providerName: _projectName,
      localDisplayLang: null,
      profileDetails: null
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "getRtl", "appConfig"]),

    getSubscriberProfileName() {

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename && this.subscriberProfileDetails.profilename.length > 15) {
        return `${this.subscriberProfileDetails.profilename.substring(0, 15)}...`;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename) {
        return this.subscriberProfileDetails.profilename;
      } else if (this.subscriberDetails.profilename && this.subscriberDetails.profilename.length > 15) {
        return `${this.subscriberDetails.profilename.substring(0, 15)}...`;
      } else {
        return this.subscriberDetails.profilename;
      }
      // if (this.subscriberDetails.data.profilename && this.subscriberDetails.data.profilename.length > 15) {
      //   return `${this.subscriberDetails.data.profilename.substring(0, 15)}...`;
      // } else {
      //   return this.subscriberDetails.data.profilename;
      // }
    },

    checkIfKidsProfile() {
      if(this.profileDetails && this.profileDetails.kidsmode === "YES") {
        return true
      } else {
        return false
      }
    },

    checkIfMultipleProfileEnabled() {
      // isMultipleProfileEnabled
      if(this.appConfig.featureEnabled.isMultipleProfileEnabled) {
        return true;
      }
    }
  },

  watch: {
    subscriberid(val) {
      if (val) {
        this.getSubscriberDetails();
      }
    },
  },

  created() {

    this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;

    this.getSubscriberDetails();

    let currentProfileDetails = localStorage.getItem("subscriberProfileDetails");
    if(currentProfileDetails) {
      currentProfileDetails = JSON.parse(currentProfileDetails);

      this.profileDetails = currentProfileDetails;
    }

    console.log("THIS IS PROFILE PAGE ----------");

    localStorage.setItem("payment_source", "Profile");

    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    console.log("The LOcal lang =====>", this.localDisplayLang);
    }
  },

  beforeDestroy() {
    document.body.style.overflow = "auto";
  },

  mounted() {
    document.body.style.overflow = "hidden";

    // window.addEventListener("localstorage-updated", (event) => {
    //   this.subscriberDetails = JSON.parse(event.detail.storage).data;
    // });

    // eventBus.$on("profile-list-response", (response) => {
    //   if (!response.reason) {
    //     // this.profiles = response.data;

    //     console.log("profile", this.subscriberDetails);

    //     response.data.forEach((element) => {
    //       if (element.profileid !== this.subscriberDetails.profileid) {
    //         this.profiles.push(element);
    //       }
    //     });

    //     this.noProfiles = false;
    //   }
    // });

    // eventBus.$on("switchProfileResponse", (response) => {
    //   if (response.success) {
    //     //logging switch profile google analytics event.
    //     let GA = {
    //       status: "Success",
    //     };
    //     this.switchProfileEvent(GA);

    //     localStorage.setItem("sessionToken", response.success);
    //     let payload = {
    //       app: "pinswitch",
    //     };
    //     eventBus.$emit("subscriberDetails", payload);
    //   }
    // });
  },

  methods: {
    ...mapActions(["actGetLogout"]),
    actRoutingProfile () {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.$router.push({name: "Profile", params: { lang: currentLanguage }})
    },
    goToSwitchProfile() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
    },

    logout() {
      eventBus.$emit("subscriberLogout");
    },

    getSubscriberDetails() {
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails"));
      }
      // eventBus.$emit("list-profiles", "profileDropdown");
    },
    // switchProfile(profile) {
    //   console.log("switch profile", profile);
    //   if (profile.profilepin === "ENABLED") {
    //     eventBus.$emit("switchProfile", profile);
    //   } else {
    //     eventBus.$emit("enableOverlayLoader", true);

    //     let payload = {
    //       profileid: profile.profileid,
    //     };
    //     eventBus.$emit("switch-profile", payload);
    //   }
    // },
    changeColor() {
      this.fillColor = "#ff9600";
      this.opacityVal = "1";
    },
    ChangeOriginal() {
      this.fillColor = "#EFEFF4";
      this.opacityVal = "0.5";
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    SwitchImg: () => import(/* webpackChunkName: "SwitchImg" */ "@/components/SvgImages/SwitchImage.vue"),
  },
  mixins: [googleAnalytics, Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.profile {
  position: absolute;
  top: 115%;
  right: 4%;
  border-radius: 10px;
  z-index: 2;
  width: 236px;
  &::before {
    content: "";
    position: absolute;
    top: -3%;
    left: 51%;
    width: 0%;
    height: 0%;
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $clr-dark-gd2;
    z-index: 1;
  }
  &-detail {
    padding: 1.2rem 1.2rem 1.5rem;
    background-color: $clr-dark-gd2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    &-logout {
      color: #ffffff;
      font-size: 14px;
      font-family: $font-regular;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 12px;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      &:hover {
        img,
        p {
          opacity: 1;
        }
      }
      img {
        cursor: pointer;
        opacity: 0.5;
        margin: 0 0.3rem;
      }
      p {
        cursor: pointer;
        opacity: 0.5;
      }
    }
    &-userdetails {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      &-profileIcon {
        width: 55px;
        height: 55px;
        margin-bottom: 0.6rem;
      }
      &-username {
        color: #ffffff;
        font-family: $font-regular;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        padding-bottom: 0.3rem;
      }
      &-email {
        color: #868688;
        font-family: $font-regular;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
        padding-bottom: 0.6rem;
      }
      &-gotoProfile {
        font-size: 0.6rem !important;
        padding: 0.2rem 1.2rem !important;
      }
      &-switchProfile {
        margin-top: 20px;
        width: 100%;
        border-top: 1.5px solid $clr-light-gd4; 
        border-bottom: 1.5px solid $clr-light-gd4; 

        display: flex;
        justify-content: center;
        &-switchButton{
          padding: 0.2rem 0.2rem 0.2rem 0.2rem;
          background:none;
          border:none;
          margin: 0.5rem;
          color:  $clr-light-gd4;
          font-weight: 600;
          font-family: $font-regular;
          font-size: 0.9rem;
          // margin-left: 3rem;
          // display: flex;
          // justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
  &-switch {
    background-color: $clr-dark-gd3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    &-title {
      color: #5c5c5d;
      font-family: $font-regular;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 12px;
      padding: 1rem 1rem 0.5rem 1rem;
    }
    &-noprofiles {
      font-family: $font-regular;
      color: #ffffff;
      opacity: 0.5;
      text-align: center;
      padding: 0.6rem;
      font-size: 12px;
    }
    &-subprofiles {
      max-height: 150px;
      // overflow-y: scroll;
      &-profile {
        display: flex;
        align-items: center;
        padding: 0.6rem 1rem;
        cursor: pointer;
        &-profileIcon {
          margin-right: 0.6rem;
          width: 29px;
          height: 29px;
        }
        &-switch-div {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .profilename {
            opacity: 0.45;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 14px;
          }
          .master-switch {
            height: 11px;
            width: 37px;
            border-radius: 1px;
            background-color: #007dff;
            color: #ffffff;
            font-family: $font-regular;
            font-size: 7px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 12px;
            text-align: center;
            display: grid;
            cursor: pointer;
          }
          .kids-switch {
            height: 11px;
            width: 37px;
            border-radius: 1px;
            background: linear-gradient(270deg, #ff8d9c 0%, #ffa3e6 100%);
            color: #000000;
            font-family: $font-regular;
            font-size: 7px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 12px;
            text-align: center;
            display: grid;
            cursor: pointer;
          }
          .switch-icon {
            margin-right: 12px;
            cursor: pointer;
          }
        }
        &:hover {
          background-color: $clr-dark-gd2;
        }
      }
      &:last-child {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 1rem;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $clr-dark-gd2;
        border-radius: 5px;
      }
    }
  }
}
//rtl class for right to left alignment.
.rtl-profile {
  left: 4%;
  top: 115%;
  right: auto;
  &::before {
    top: -2%;
    left: 30%;
  }
}

@media only screen and (max-width: 576px) {
  .profile {
    right: 2%;
  }

  .rtl-profile {
    left: 2%;
    right: auto;
  }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .profile {
    right: 2%;
  }

  .rtl-profile {
    left: 2%;
    right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .profile {
    right: 2%;
  }

  .rtl-profile {
    left: 2%;
    right: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1028px) {
  .profile {
    right: 2%;
  }

  .rtl-profile {
    left: 2%;
    right: auto;
  }
}
</style>
